import { config as appConfig } from "/app.config"

export const podcasts = (state) => {
  return state.podcasts
}

export const podcast = (state) => {
  return state.podcast
}

export const podcastCategories = (state) => {
  return state.categories
}

export const podcastRSSLink = (state) => {
  if (!state.podcast.key) {
    return ""
  }

  return appConfig.podcastFeedURL + state.podcast.key + "/rss"
}
