function initialState() {
  return {
    podcasts: null,

    podcast: {
      subtitle: "",
      image_key: "",
      pretty_url: null,
      published: true,
      image: "",
      owner_email: "",
      keywords: [],
      complete: false,
      copyright: "",
      title: "",
      total_size: 0,
      owner_name: "",
      description: "",
      parent: "",
      show_type: "",
      link: "",
      key: "",
      categories: [],
      language: "",
      author: "",
      explicit: false,
      summary: "",
      station_key: "",
      block: false,
      third_party_content: false,
    },

    categories: [
      "Arts",
      "Arts/Design",
      "Arts/Fashion & Beauty",
      "Arts/Food",
      "Arts/Literature",
      "Arts/Performing Arts",
      "Arts/Visual Arts",
      "Business",
      "Business/Business News",
      "Business/Careers",
      "Business/Investing",
      "Business/Management & Marketing",
      "Business/Shopping",
      "Comedy",
      "Education",
      "Education/Educational Technology",
      "Education/Higher Education",
      "Education/K-12",
      "Education/Language Courses",
      "Education/Training",
      "Games & Hobbies",
      "Games & Hobbies/Automotive",
      "Games & Hobbies/Aviation",
      "Games & Hobbies/Hobbies",
      "Games & Hobbies/Other Games",
      "Games & Hobbies/Video Games",
      "Government & Organizations",
      "Government & Organizations/Local",
      "Government & Organizations/National",
      "Government & Organizations/Non-Profit",
      "Government & Organizations/Regional",
      "Health",
      "Health/Alternative Health",
      "Health/Fitness & Nutrition",
      "Health/Self-Help",
      "Health/Sexuality",
      "Kids & Family",
      "Music",
      "News & Politics",
      "Religion & Spirituality",
      "Religion & Spirituality/Buddhism",
      "Religion & Spirituality/Christianity",
      "Religion & Spirituality/Hinduism",
      "Religion & Spirituality/Islam",
      "Religion & Spirituality/Judaism",
      "Religion & Spirituality/Other",
      "Religion & Spirituality/Spirituality",
      "Science & Medicine",
      "Science & Medicine/Medicine",
      "Science & Medicine/Natural Sciences",
      "Science & Medicine/Social Sciences",
      "Society & Culture",
      "Society & Culture/History",
      "Society & Culture/Personal Journals",
      "Society & Culture/Philosophy",
      "Society & Culture/Places & Travel",
      "Sports & Recreation",
      "Sports & Recreation/Amateur",
      "Sports & Recreation/College & High School",
      "Sports & Recreation/Outdoor",
      "Sports & Recreation/Professional",
      "Technology",
      "Technology/Gadgets",
      "Technology/Tech News",
      "Technology/Podcasting",
      "Technology/Software How-To",
      "TV & Film",
    ],
  }
}

let state = initialState()

export { initialState, state }
