import toolsClient from "@/api/toolsClient"

export const getEpisodes = async (podcastKey, { limit, search, offset, order }) => {
  const params = new URLSearchParams({
    limit: limit.toString(),
    offset: offset.toString(),
    order,
    ...(search && { search }),
  })

  const response = await toolsClient.get(`/podcasts/${podcastKey}/episodes?${params.toString()}`)

  return response.data
}

export const getEpisode = async (podcastKey, episodeKey) => {
  const response = await toolsClient.get(`/podcasts/${podcastKey}/episodes/${episodeKey}`)

  return response.data
}

export const updateEpisode = async (episode) => {
  let data = new FormData()

  data.append("episode", JSON.stringify(episode))

  if (episode.logoFile) {
    data.append("logo", episode.logoFile)
  }

  const response = await toolsClient.put(`/podcasts/${episode.podcast}/episodes/${episode.key}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })

  return response.data
}

export const deleteEpisode = async (podcastKey, episodeKey) => {
  await toolsClient.delete(`/podcasts/${podcastKey}/episodes/${episodeKey}`)
}

export const uploadEpisodeFile = async (podcastKey, file, config) => {
  const data = new FormData()

  data.append("name", file.name)
  data.append("file", file)

  const response = await toolsClient.post(`/podcasts/upload/${podcastKey}`, data, {
    ...config,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })

  return response.data
}

export const getEpisodeUploadingStatus = async (taskId) => {
  const response = await toolsClient.get(`/podcasts/upload/task/${taskId}`)

  return response.data
}
