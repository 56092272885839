import toolsClient from "@/api/toolsClient"
/**
 * @typedef {Object} Stats
 * @property {string} episodeKey
 * @property {string} episodeName
 * @property {string} podcastKey
 * @property {string} podcastName
 * @property {number} totalBytes
 * @property {number} downloadedBytes
 * @property {number} downloads
 * @property {number} uniqueListeners
 * @property {number} totalDuration
 * @property {number} streamedDuration
 * @property {number} downloadsPerListener
 * @property {number} downloadPercentage
 * @property {string} date
 */

/**
 * Fetches account podcasts analytics data.
 *
 * @async
 * @function getPodcastsAnalytics
 * @param {string} accountKey - Account key
 * @param {Object} params - Query parameters
 * @returns {Promise<{
 * summaryStats: Stats,
 * periodDateStats: Stats[],
 * episodeDateStats: Stats[]
 * }>} Podcast statistics data.
 * @see {@link https://stream-tools.zenomedia.com/swagger-ui/index.html#/account-controller/getAccountPodcastStatsPeriodV2}
 */
export const getPodcastsAnalytics = async (accountKey, params) => {
  const searchParams = new URLSearchParams()

  Object.keys(params).forEach((key) => searchParams.append(key, params[key]))

  const response = await toolsClient.get(`/accounts/${accountKey}/stats/podcast/period/v2?` + searchParams.toString())

  return response.data
}
