import merge from "lodash/merge"

const modules = require.context("./", true, /\.json$/)

const messages = modules.keys().reduce((acc, key) => {
  const lang = key.match(/^\.\/(\w+)\//)?.[1]

  if (lang) {
    acc[lang] = merge(acc[lang] || {}, modules(key))
  }

  return acc
}, {})

export default messages
