/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue"
import Router from "vue-router"
import accountRouter from "@/routes/accountRouter"
import portalRouter from "@/routes/portal/index"
import { updateIntercom } from "@/services/IntercomService"
import { i18n } from "@/plugins/i18n"

Vue.use(Router)

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash, offset: { x: 0, y: 110 } }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    ...portalRouter,
    {
      path: "/",
      name: "home",
      component: () => import("@/layouts/main/Main"),
      meta: {
        pageTitle: "Home",
        rule: "owner",
      },
      children: [
        ...accountRouter,
        {
          path: "/stations/:stationKey",
          name: "station.index",
          component: () => import("@/views/Stations/Station.vue"),
          meta: {
            pageTitle: "Station",
            rule: "owner",
          },
        },
        {
          path: "/profile/settings/",
          name: "profile.settings",
          component: () => import("@/views/Profile/ProfileSettings"),
          meta: {
            pageTitle: i18n.t("profile.settings_title"),
            rule: "owner",
          },
        },
        {
          path: "/not-found",
          name: "page-error-not-found",
          component: () => import("@/views/pages/ErrorNotFound"),
          meta: {
            pageTitle: "Not found",
            rule: "*",
          },
        },
        {
          path: "/forbidden",
          name: "page-error-forbidden",
          component: () => import("@/views/pages/ErrorForbidden"),
          meta: {
            pageTitle: "Forbidden",
            rule: "*",
          },
        },
        {
          path: "/bad-request",
          name: "page-bad-request",
          component: () => import("@/views/pages/ErrorBadRequest"),
          meta: {
            pageTitle: "Bad request",
            rule: "*",
          },
        },
      ],
    },

    {
      path: "*",
      redirect: "/",
      meta: {
        rule: "*",
      },
    },
  ],
})

router.afterEach((to) => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg")

  if (appLoading) {
    appLoading.style.display = "none"
  }

  if (to.meta.pageTitle) {
    document.title = to.meta.pageTitle
  }

  updateIntercom()
})

export default router
