<template>
  <div class="vx-row">
    <label
      v-if="$slots.label || !vertical"
      :class="{ 'sm:w-1/4 sm:text-right': !vertical, 'sm:pt-2': !inline }"
      class="vx-col w-full pt-0">
      <slot name="label"></slot>
      <span v-if="required" class="text-danger"> *</span>
    </label>
    <div :class="{ 'sm:w-3/4 ': !vertical }" class="vx-col w-full">
      <slot name="field"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "form-row",

  inheritAttrs: true,

  props: {
    vertical: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped></style>
