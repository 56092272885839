import * as api from "@/api/episode.api"

export const getEpisodes = async ({ commit }, { podcastKey, params }) => {
  try {
    let episodes = await api.getEpisodes(podcastKey, params)

    commit("setEpisodes", episodes.data)

    return episodes
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const getEpisode = async ({ commit }, { podcastKey, episodeKey }) => {
  try {
    let episode = await api.getEpisode(podcastKey, episodeKey)

    commit("setEpisode", episode)

    return episode
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const updateEpisode = async ({ commit }, data) => {
  try {
    let episode = await api.updateEpisode(data)

    commit("setEpisode", episode)

    return episode
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const resetEpisode = async ({ commit }) => {
  try {
    commit("resetEpisode")
  } catch (e) {
    console.log(e)
    throw e
  }
}
