import UserService from "@/services/UserService"

export const fetchUser = async ({ commit }) => {
  const user = await UserService.getUser()

  localStorage.setItem("userInfo", JSON.stringify(user))

  commit("setUser", user)

  return user
}

export const updateUserData = async ({ commit }, data) => {
  const user = await UserService.updateUser(data)

  await UserService.updateUserProfile({
    firstName: data.basic_info.first_name,
    lastName: data.basic_info.last_name,
  })

  localStorage.setItem("userInfo", JSON.stringify(user))

  commit("setUser", user)

  return user
}
