const modules = require.context("./", true, /\.json$/)

const messages = {}

modules.keys().forEach((key) => {
  // Extract the language code (e.g., 'en') from the path
  const [, lang] = key.match(/^\.\/(\w+)\//) || []

  if (lang) {
    // Ensure the language key exists in the messages object
    messages[lang] = messages[lang] || {}

    // Import the module and merge it into the appropriate language object
    const module = modules(key)
    Object.assign(messages[lang], module)
  }
})

export default messages
