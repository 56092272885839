import VueI18n from "vue-i18n"
import Vue from "vue"
import { config } from "../../app.config"
import merge from "lodash/merge"

import NistarAdsMessages from "@/locales/NistarAds"
import AiirMessages from "@/locales/Aiir"
import defaultMessages from "@/locales/default"

let messages = defaultMessages

if (config.appName === "Aiir") {
  messages = merge(defaultMessages, AiirMessages)
} else if (config.appName === "NistarAds") {
  messages = merge(defaultMessages, NistarAdsMessages)
}

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages,
})
