import Vue from "vue"
import { config as appConfig, config } from "/app.config"

Vue.mixin({
  data: function () {
    return {
      appConfig: config,
    }
  },

  computed: {
    isAutomationEnabled() {
      return appConfig.automation
    },
    isZeno() {
      return appConfig.isZeno
    },
    isAiir() {
      return appConfig.appName === "Aiir"
    },
    isNistar() {
      return appConfig.appName === "NistarAds"
    },
    isWhiteLabel() {
      return this.isAiir || this.isNistar
    },
  },
})
