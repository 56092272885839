import { i18n } from "@/plugins/i18n"

export default [
  {
    path: "episodes",
    component: () => import("@/views/Podcasts/Episodes/PodcastEpisodes.vue"),
    children: [
      {
        path: "",
        name: "episodes",
        component: () => import("@/views/Podcasts/Episodes/PodcastEpisodeList.vue"),
        meta: {
          pageTitle: i18n.t("episodes.list_title"),
          parent: "podcasts",
          rule: "owner",
        },
      },
      {
        path: "create",
        component: () => import("@/views/Podcasts/Episodes/PodcastEpisodeAdd.vue"),
        name: "episodes.create",
        props: true,
        meta: {
          pageTitle: i18n.t("episodes.add_title"),
          parent: "episodes",
          rule: "owner",
        },
      },
      {
        path: ":episodeKey",
        component: () => import("@/views/Podcasts/Episodes/PodcastEpisode.vue"),
        name: "episode",
        props: true,
        meta: {
          pageTitle: "Episode",
          parent: "episodes",
          rule: "owner",
        },
        children: [
          {
            name: "episode.edit",
            path: "edit",
            props: true,
            component: () => import("@/views/Podcasts/Episodes/PodcastEpisodeEdit.vue"),
            meta: {
              pageTitle: i18n.t("episodes.settings_title"),
              parent: "episodes",
              rule: "owner",
            },
          },
        ],
      },
    ],
  },
]
