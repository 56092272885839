import { config } from "/app.config"
import { getUserProfile } from "@/services/UserService"

export const CRISP_WEBSITE_ID = process.env.VUE_APP_CRISP_WEBSITE_ID

export const isCrispChatEnabled = () => {
  return CRISP_WEBSITE_ID && CRISP_WEBSITE_ID.length && config.isZeno
}

const isCrispChatInitialized = () => {
  return window.$crisp !== undefined
}

export const initializeCrispChat = async (account, accountMainPlan) => {
  if (!isCrispChatEnabled()) {
    return
  }

  const profile = await getUserProfile()
  if (profile.portal_rights && profile.portal_rights.includes("admin")) {
    // don't enable CrispChat for portal admins
    return
  }

  if (!isCrispChatInitialized()) {
    const script = document.createElement("script")
    script.setAttribute("type", "text/javascript")
    script.innerHTML = `window.$crisp=[];window.CRISP_WEBSITE_ID="${CRISP_WEBSITE_ID}";window.CRISP_TOKEN_ID="${profile.crispToken}";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`
    document.head.appendChild(script)
  }

  setCrispSessionData(profile, account, accountMainPlan)
  showCrispChatBox()
}

export const resetCrispSession = () => {
  if (!isCrispChatInitialized()) {
    return
  }

  window.CRISP_TOKEN_ID = null
  window.$crisp.push(["do", "session:reset"])
}

export const showCrispChatBox = () => {
  if (!isCrispChatInitialized()) {
    return
  }

  window.$crisp.push(["do", "chat:show"])
}

export const hideCrispChatBox = () => {
  if (!isCrispChatInitialized()) {
    return
  }

  window.$crisp.push(["do", "chat:hide"])
}

const setCrispSessionData = (profile, account, accountMainPlan) => {
  if (!isCrispChatInitialized()) {
    return
  }

  if (profile) {
    window.$crisp.push(["set", "user:email", [profile.email, profile.crispToken]]);
    if (profile.basic_info) {
      window.$crisp.push(["set", "user:nickname", [profile.basic_info.first_name + " " + profile.basic_info.last_name]]);
    } else {
      window.$crisp.push(["set", "user:nickname", [profile.email]])
    }
  }

  if (account && account.key) {
    window.$crisp.push(["set", "user:company", [account.company ? account.company : account.key, { url: account.key }]]);
    window.$crisp.push(["set", "session:data", ["account_key", account.key]]);
    window.$crisp.push(["set", "session:data", ["is_gold_brd", account.gold_brd ? true : false]]);
  }

  window.$crisp.push(["set", "session:data", ["is_trial_plan", accountMainPlan && accountMainPlan.status === "trialing"]]);
  window.$crisp.push(["set", "session:data", ["is_paid_plan", accountMainPlan && (accountMainPlan.status === "active" || accountMainPlan.status === "past_due")]]);
}
