import UITable from "./UITable.vue"
import UITh from "./UITh.vue"
import UITr from "./UITr.vue"
import UITd from "./UITd.vue"
import UITimg from "./UITimg.vue"

export default (Vue) => {
  Vue.component("ui-table", UITable)
  Vue.component("ui-th", UITh)
  Vue.component("ui-tr", UITr)
  Vue.component("ui-td", UITd)
  Vue.component("ui-timg", UITimg)
}
