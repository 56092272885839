function initialState() {
  return {
    podcastsAnalytics: null,
    selectedPodcasts: [],
    period: {
      from_date: "",
      to_date: "",
    },
  }
}

let state = initialState()

export { initialState, state }
