import { getPodcastsAnalytics } from "@/api/analyticsPodcasts.api"

export const fetchPodcastsAnalytics = async (context, { accountId, params }) => {
  const podcastsAnalytics = await getPodcastsAnalytics(accountId, params)

  context.commit("setPodcastsAnalytics", podcastsAnalytics)
}

export const selectPodcasts = async (context, payload) => {
  context.commit("setSelectedPodcasts", payload)
}

export const setAnalyticsPeriod = async (context, payload) => {
  context.commit("setPeriod", payload)
}
