export const podcastsAnalytics = (state) => {
  return state.podcastsAnalytics
}

export const summaryStats = (state) => {
  if (state.podcastsAnalytics?.summaryStats && state.podcastsAnalytics?.summaryStats?.totalBytes) {
    return state.podcastsAnalytics.summaryStats
  }

  return false
}

export const periodStats = (state) => {
  return state.podcastsAnalytics?.periodDateStats || []
}
export const episodeDateStats = (state) => {
  return state.podcastsAnalytics?.episodeDateStats || []
}

export const analyticsSelectedPodcasts = (state) => {
  return state.selectedPodcasts
}

export const analyticsPeriod = (state) => {
  return state.period
}
