import toolsClient from "@/api/toolsClient"

export const getPodcasts = async (accountKey) => {
  const response = await toolsClient.get(`/podcasts/?accountKey=${accountKey}`)

  return response.data
}

export const getPodcast = async (podcastKey) => {
  const response = await toolsClient.get(`/podcasts/${podcastKey}`)

  return response.data
}

export const storePodcast = async (accountKey, podcastData) => {
  let data = new FormData()

  data.append("podcast", JSON.stringify(podcastData))

  if (podcastData.logoFile) {
    data.append("logo", podcastData.logoFile)
  }

  const response = await toolsClient.post(`/podcasts/?accountKey=${accountKey}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })

  return response.data
}

export const updatePodcast = async (podcastData) => {
  let data = new FormData()

  data.append("podcast", JSON.stringify(podcastData))

  if (podcastData.logoFile) {
    data.append("logo", podcastData.logoFile)
  }

  const response = await toolsClient.put(`/podcasts/${podcastData.key}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })

  return response.data
}

export const deletePodcast = async (podcastKey) => {
  await toolsClient.delete(`/podcasts/${podcastKey}`)
}

export const importPodcast = async (accountKey, feedURL) => {
  const response = await toolsClient.post(`/podcasts/${accountKey}/import?accountKey=${accountKey}`, {
    feed_url: feedURL,
  })

  return response.data
}

export const getTaskStatus = async (taskId) => {
  const response = await toolsClient.get(`/podcasts/import/task/${taskId}`)

  return response.data
}

export const podcastPortalSearch = async ({ query = "", limit = 10, offset = 0 }) => {
  const response = await toolsClient.get(`/portal/search/podcasts?limit=${limit}&offset=${offset}&search=${query}`)

  return response.data
}

export const getDistributionPlatforms = async (podcastKey) => {
  const response = await toolsClient.get(`/podcasts/${podcastKey}/distributionPlatforms`)

  return response.data
}

export const updateDistributionPlatforms = async (podcastKey, data) => {
  const response = await toolsClient.put(`/podcasts/${podcastKey}/distributionPlatforms`, data)

  return response.data
}
