import * as api from "@/api/podcasts.api"

export const resetPodcasts = async ({ commit }) => {
  try {
    commit("setPodcasts", [])
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const resetPodcast = async ({ commit }) => {
  try {
    commit("resetPodcast")
    commit("Episode/reset", [], { root: true })
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const getPodcasts = async ({ commit }, accountKey) => {
  try {
    let podcasts = await api.getPodcasts(accountKey)

    commit("setPodcasts", podcasts)

    return podcasts
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const getPodcast = async ({ commit }, payload) => {
  try {
    const podcast = await api.getPodcast(payload)

    commit("setPodcast", podcast)

    return podcast
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const updatePodcast = async ({ commit }, data) => {
  try {
    let podcast = await api.updatePodcast(data)

    commit("setPodcast", podcast)

    return podcast
  } catch (e) {
    console.log(e)
    throw e
  }
}
