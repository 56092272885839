/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue"

import App from "./App.vue" // Vuesax Component Framework
import Vuesax from "vuesax"
import "material-icons/iconfont/material-icons.css" //Material Icons
import "material-icons/iconfont/outlined.css" //Material Icons
import "vuesax/dist/vuesax.css" // Vuesax
import "@/common/filters"
import "@/common/mixins"
import "@/common/mixins/whiteLabelingMixin"
import "@/common/validations" // Theme Configurations
import "../themeConfig.js" // Globally Registered Components
import "./globalComponents.js" // Styles: SCSS
import "./assets/scss/main.scss" // Tailwind
import "@/assets/css/main.css" // ACL
// Vue Router
import router from "./router" // Vuexy Admin Filters
import "./filters/filters" // Clipboard
import VueClipboard from "vue-clipboard2"
// Vue Select
import vSelect from "vue-select"
// Vuex Store
import store from "./store/store" // Vuejs - Vue wrapper for hammerjs
import { VueHammer } from "vue2-hammer"
// PrismJS
import "prismjs"
import "prismjs/themes/prism-tomorrow.css" // Auth0 Plugin
import AuthPlugin from "@/plugins/auth"
// VueI18n
import { i18n } from "@/plugins/i18n"
// Loading Button
import LButton from "@/views/components/loading-button/LoaderButton"

import VueGtag from "vue-gtag"
import CopyButton from "@/components/app/CopyButton.vue"
import FormErrorMessage from "@/components/app/Form/FormErrorMessage.vue"
import FormRow from "@/components/app/Form/FormRow.vue"
import CustomSelect from "@/components/select/CustomSelect.vue"
import PageSizeSelect from "@/components/select/PageSizeSelect.vue"
import UIPagination from "@/components/ui/ui-pagination/UIPagination.vue"
import UISelect from "@/components/ui/ui-select/UISelect.vue"
import UISelectItem from "@/components/ui/ui-select/UISelectItem.vue"
import UITable from "@/components/ui/ui-table/index"
Vue.use(UITable)

// Time Picker
import VueTimepicker from "vue2-timepicker"

// Color Picker
import UIColorPicker from "@/components/ui/ui-color-picker/UIColorPicker.vue"

Vue.use(Vuesax)

Vue.use(VueClipboard)

Vue.component("vSelect", vSelect)

Vue.use(VueHammer)

Vue.use(AuthPlugin)

Vue.prototype.i18n = i18n

window.Vue = Vue

Vue.component("btn-copy", CopyButton)
Vue.component("f-error", FormErrorMessage)
Vue.component("form-row", FormRow)
Vue.component("custom-select", CustomSelect)
Vue.component("page-size-select", PageSizeSelect)
Vue.component("ui-pagination", UIPagination)
Vue.component("ui-select", UISelect)
Vue.component("ui-select-item", UISelectItem)
Vue.component("ui-color-picker", UIColorPicker)

Vue.component("l-button", LButton)

Vue.component("vue-timepicker", VueTimepicker)

// Feather font icon
require("./assets/css/iconfont.css")

Vue.config.performance = true
Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_TAG_ID },
})

const vue = new Vue({
  router,
  store,
  // acl,
  i18n,
  render: (h) => h(App),
}).$mount("#app")

export const notify = vue.$vs.notify
