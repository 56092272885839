import stationPodcastEpisodeRouter from "@/routes/stationPodcastEpisodeRouter"
import { i18n } from "@/plugins/i18n"

export default [
  {
    path: "podcasts",
    component: () => import("@/views/Podcasts/Podcasts.vue"),
    children: [
      {
        path: "",
        name: "station.podcasts.list",
        component: () => import("@/views/Podcasts/PodcastList.vue"),
        meta: {
          pageTitle: i18n.t("podcasts.list_title"),
          parent: "stations",
          rule: "owner",
        },
      },
      {
        path: "create",
        component: () => import("@/views/Podcasts/PodcastAdd.vue"),
        name: "station.podcasts.create",
        props: true,
        meta: {
          pageTitle: i18n.t("podcasts.add_title"),
          parent: "stations",
          rule: "owner",
        },
      },
      {
        path: ":podcastKey",
        component: () => import("@/views/Podcasts/Podcast.vue"),
        name: "station.podcast",
        props: true,
        meta: {
          pageTitle: "Podcast info",
          parent: "stations",
          rule: "owner",
        },
        children: [
          {
            name: "station.podcast.settings",
            path: "settings/",
            component: () => import("@/views/Podcasts/PodcastSettings.vue"),
            meta: {
              pageTitle: i18n.t("podcasts.settings_title"),
              parent: "stations",
              rule: "owner",
            },
          },
          ...stationPodcastEpisodeRouter,
        ],
      },
    ],
  },
]
