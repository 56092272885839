import { deleteCookie } from "@/helpers";
import { resetCrispSession } from "@/services/CrispChatService";

export const login = async ({ commit }, auth) => {
  commit("setAuth", auth);
};

export const logout = async ({ commit }) => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("userInfo");

  deleteCookie("growthpanel");

  resetCrispSession();

  commit("logout");
};
