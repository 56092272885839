function initialState() {
  return {
    episodes: [],

    episode: {
      subtitle: null,
      image_key: null,
      file_url: "",
      image: "",
      duration: 0,
      guid: "",
      size: 0,
      title: "",
      published: true,
      upload_status: "",
      rich_description: null,
      description: "",
      tags: [],
      season: 0,
      link: null,
      key: "",
      podcast: "",
      episode: 0,
      episode_type: null,
      author: "",
      explicit: false,
      summary: null,
      publish_date: "",
      duration_hms: "",
      block: false,
    },
  }
}

let state = initialState()

export { initialState, state }
