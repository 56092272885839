import toolsClient from "@/api/toolsClient"

export const getPodcastRecordingScheduleList = async (podcastKey) => {
  const response = await toolsClient.get(`/podcasts/${podcastKey}/recordingScheduleTable`)

  return response.data
}

export const createPodcastRecordingSchedule = async (data) => {
  const response = await toolsClient.post(`/podcasts/${data.podcastKey}/recordingSchedule`, data)

  return response.data
}

export const updatePodcastRecordingSchedule = async (data) => {
  const response = await toolsClient.put(`/podcasts/${data.podcastKey}/recordingSchedule/${data.id}`, data)

  return response.data
}

export const deletePodcastRecordingSchedule = async (podcastKey, scheduleId) => {
  await toolsClient.delete(`/podcasts/${podcastKey}/recordingSchedule/${scheduleId}`)
}
