import subscriptionApi from "@/api/subscription.api";
import { notificationError } from "@/common/helpers/notifications.helper";
import { i18n } from "@/plugins/i18n";

export const getAccountSubscriptions = async (id) => {
  return await subscriptionApi.list(id);
};

export const getStationSubscriptions = async (id) => {
  return await subscriptionApi.list(id, "stations");
};

export const getStationSubscription = async (id, priceId) => {
  return await subscriptionApi.get(id, priceId, "stations");
};

export const cancelStationSubscription = async (id, priceId) => {
  return await subscriptionApi.cancel(id, priceId, "stations");
};

export const createAccountSubscription = async (id, data) => {
  return await subscriptionApi.create(id, data);
};

export const getAccountPlans = async (id) => {
  return await subscriptionApi.getAccountPlans(id);
};

export const getAccountPlanFeatures = async (id) => {
  return await subscriptionApi.getAccountPlansFeatures(id);
};

export const checkCanUpdateSubscription = async (accountId, priceId, quantity, promoCode) => {
  const result = await subscriptionApi.canUpdateSubscription(
    accountId,
    priceId,
    quantity,
    promoCode
  );

  if (!result.updateAllowed) {
    let message = "Unknown error occurred";

    if (result.reason?.stationsCountExceeded) {
      message = i18n.t("subscriptions.stations_count_exceeded");
    } else if (result.reason?.podcastsCountExceeded) {
      message = i18n.t("subscriptions.podcasts_count_exceeded");
    } else if (result.reason?.maxTracksExceeded) {
      message = i18n.t("subscriptions.max_tracks_exceeded");
    } else if (result.reason?.maxBitRateExceeded) {
      message = i18n.t("subscriptions.max_bit_rate_exceeded");
    } else if (result.reason?.invalidQuantity) {
      message = i18n.t("subscriptions.invalid_quantity");
    } else if (result.reason?.invalidPromoCode) {
      message = i18n.t("subscriptions.invalid_promo_code");
    } else if (result.reason?.noPaymentMethod) {
      message = i18n.t("subscriptions.no_payment_method");
    }

    await notificationError(
      message,
      i18n.t("subscriptions.plan_update_error"),
      12000
    );
  }

  return result.updateAllowed;
};

export const addAccountSubscriptionPromoCode = async (accountId, subscriptionId, promoCode) => {
  return await subscriptionApi.addAccountSubscriptionPromoCode(
    accountId,
    subscriptionId,
    promoCode
  );
};

export default {
  getAccountSubscriptions,
  getStationSubscriptions,
  getStationSubscription,
  cancelStationSubscription,
  createAccountSubscription,
  getAccountPlans,
  getAccountPlanFeatures,
  checkCanUpdateSubscription,
  addAccountSubscriptionPromoCode,
};
