import { initialState } from "./state"

export const setUser = (state, payload) => {
  if (payload.basic_info.birthday === "None") {
    payload.basic_info.birthday = null
  }

  state.user = payload
}

export const reset = (state) => {
  const s = initialState()

  Object.keys(s).forEach((key) => {
    state[key] = s[key]
  })
}
