import Vue from "vue"
import { localeNum } from "@/helpers"

Vue.mixin({
  methods: {
    returnUrl(to) {
      const route = this.$router.resolve(to)

      return new URL(route.href, window.location.origin).href
    },

    async validate(callback) {
      const success = await this.$refs.form.validate()

      if (success) {
        return callback()
      } else {
        this.notifWarning("Please check inputs and try again.", "Validation error")
      }
    },

    notifSuccess(message = "", title = "", time = 8000, position = "top-right") {
      this.$vs.notify({
        position: position,
        time: time,
        title: title,
        text: message,
      })
    },

    notifWarning(message = "", title = "", time = 8000, position = "top-right") {
      this.$vs.notify({
        color: "warning",
        position: position,
        time: time,
        title: title,
        text: message,
      })
    },

    notifError(message = "", title = "", time = 8000, position = "top-right") {
      this.$vs.notify({
        color: "danger",
        position: position,
        time: time,
        title: title,
        text: message,
      })
    },

    notifUnexpectedError(message = "Please reload the page and try again.", title = "Something went wrong") {
      this.$vs.notify({
        color: "danger",
        position: "top-right",
        time: 8000,
        title: title,
        text: message,
      })
    },

    onCopy() {
      this.notifSuccess("Copied!")
    },

    clonedObject(obj) {
      return JSON.parse(JSON.stringify(obj))
    },

    localeNum(number, decPlaces) {
      return localeNum(number, decPlaces)
    },

    abbrNum(number, decimals) {
      if (number % 1 === 0) {
        number = parseInt(number)
      }

      if (typeof decimals == "undefined") {
        decimals = (number > 0 && number < 1) || number > 1000 || !Number.isInteger(number) ? 2 : 0
      }

      // 2 decimal places => 100, 3 => 1000, etc
      let decPlaces = Math.pow(10, decimals)

      // Enumerate number abbreviations
      let abbrev = [" K", " M", " B", " T"]
      let abbr = ""

      // Go through the array backwards, so we do the largest first
      for (let i = abbrev.length - 1; i >= 0; i--) {
        // Convert array index to "1000", "1000000", etc
        let size = Math.pow(10, (i + 1) * 3)
        // If the number is bigger or equal do the abbreviation
        if (size <= number) {
          // Here, we multiply by decPlaces, round, and then divide by decPlaces.
          // This gives us nice rounding to a particular decimal place.
          number = Math.round((number * decPlaces) / size) / decPlaces

          // Handle special case where we round up to the next abbreviation
          if (number === 1000 && i < abbrev.length - 1) {
            number = 1
            i++
          }

          // Add the letter for the abbreviation
          abbr = abbrev[i]
          break
        }
      }
      if (abbr === "") {
        return this.localeNum(number, decimals)
      } else {
        return this.localeNum(number) + abbr
      }
    },
  },
})
