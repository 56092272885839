<template>
  <img :alt="alt" :src="src" class="responsive" style="height: 55px; width: 55px" @error="setAltImg" />
</template>

<script>
export default {
  name: "UITimg",

  inheritAttrs: true,

  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: "",
    },
  },

  methods: {
    setAltImg(event) {
      event.target.src = "/img-placeholder.png"
    },
  },
}
</script>
