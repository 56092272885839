import { initialState } from "./state"
import { getOptimizedImage } from "@/helpers"

export const setPodcasts = (state, payload) => {
  let podcasts = payload

  podcasts = podcasts.map((podcast) => {
    if (typeof podcast.image !== "undefined" && podcast.image.length) {
      podcast.logo = getOptimizedImage("https://" + podcast.image + "?u=" + Date.now(), 170, 170)
    } else {
      podcast.logo = false
    }

    return podcast
  })

  state.podcasts = podcasts
}

export const setPodcast = (state, payload) => {
  let podcast = payload

  if (typeof podcast.image !== "undefined" && podcast.image.length) {
    podcast.logo = getOptimizedImage("https://" + podcast.image + "?u=" + Date.now(), 170, 170)
  }

  state.podcast = payload
}

export const resetPodcast = (state) => {
  state.podcast = {
    subtitle: "",
    image_key: "",
    pretty_url: null,
    image: "",
    owner_email: "",
    keywords: [],
    complete: false,
    copyright: "",
    title: "",
    total_size: 0,
    owner_name: "",
    description: "",
    parent: "",
    show_type: "",
    link: "",
    key: "",
    categories: [],
    language: "",
    author: "",
    explicit: false,
    summary: "",
    station_key: "",
    block: false,
  }
}

export const reset = (state) => {
  const s = initialState()

  Object.keys(s).forEach((key) => {
    state[key] = s[key]
  })
}
