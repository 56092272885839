<!-- =========================================================================================
  File Name: App.vue
  Description: Main vue file - APP
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="app" :class="vueAppClasses">
    <router-view @setAppClasses="setAppClasses" />
  </div>
</template>

<script>
import { config } from "/app.config"
import themeConfig from "@/../themeConfig.js"
import { initializeIntercom, isIntercomEnabled, shutdownIntercom } from "@/services/IntercomService"
import { mapGetters } from "vuex"
import { initializeCrispChat, hideCrispChatBox } from "@/services/CrispChatService"

export default {
  data() {
    return {
      vueAppClasses: [],
    }
  },

  computed: {
    ...mapGetters("AccountSubscriptions", ["accountSubscriptions", "accountMainPlan"]),
    ...mapGetters("Account", ["accounts", "account"]),

    intercomEnabled() {
      return isIntercomEnabled()
    },
  },

  watch: {
    "$store.state.theme"(val) {
      this.toggleClassInBody(val)
    },
    "$vs.rtl"(val) {
      document.documentElement.setAttribute("dir", val ? "rtl" : "ltr")
    },
    accounts() {
    },
    accountSubscriptions: {
      handler() {
        if (this.accountSubscriptions !== null) {
          if (this.intercomEnabled && this.account?.use_intercom) {
            hideCrispChatBox()
            initializeIntercom()
          } else {
            shutdownIntercom()
            initializeCrispChat(this.account, this.accountMainPlan)
          }
        }
      },
      immediate: true,
    },
  },

  methods: {
    toggleClassInBody(className) {
      if (className === "dark") {
        if (document.body.className.match("theme-semi-dark")) document.body.classList.remove("theme-semi-dark")
        document.body.classList.add("theme-dark")
      } else if (className === "semi-dark") {
        if (document.body.className.match("theme-dark")) document.body.classList.remove("theme-dark")
        document.body.classList.add("theme-semi-dark")
      } else {
        if (document.body.className.match("theme-dark")) document.body.classList.remove("theme-dark")
        if (document.body.className.match("theme-semi-dark")) document.body.classList.remove("theme-semi-dark")
      }
    },
    setAppClasses(classesStr) {
      this.vueAppClasses.push(classesStr)
    },
    handleWindowResize() {
      this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth)

      // Set --vh property
      document.documentElement.style.setProperty("--vh", `${window.innerHeight * 0.01}px`)
    },
    handleScroll() {
      this.$store.commit("UPDATE_WINDOW_SCROLL_Y", window.scrollY)

      if (document.querySelector(".vue-back-to-top")) {
        const crispBtn = document.querySelector("#crisp-chatbox > DIV > a[data-visible]")
        if (crispBtn) {
          if (window.scrollY > 500) { // back-to-top's visibleoffset
            crispBtn.classList.add("shift-up")
          } else {
            crispBtn.classList.remove("shift-up")
          }
        }
      }
    },
  },

  mounted() {
    this.vueAppClasses.push(`theme-${config.appName.replace(" ", "-")}`)
    this.toggleClassInBody(themeConfig.theme)
    this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth)

    const vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  },

  async created() {
    const dir = this.$vs.rtl ? "rtl" : "ltr"
    document.documentElement.setAttribute("dir", dir)

    window.addEventListener("resize", this.handleWindowResize)
    window.addEventListener("scroll", this.handleScroll)
  },

  destroyed() {
    window.removeEventListener("resize", this.handleWindowResize)
    window.removeEventListener("scroll", this.handleScroll)
  },
}
</script>

<style>
.con-vs-dialog {
  z-index: 55000;
}

#crisp-chatbox > DIV > a[data-visible] {
  right: 18px !important;
}
#crisp-chatbox > DIV > a[data-visible="false"] {
  bottom: 50px !important;
}
#crisp-chatbox > DIV > a[data-visible="false"].shift-up {
  bottom: calc(5% + 38px + 5px) !important;
}
</style>
