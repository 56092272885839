import { getOptimizedImage } from "@/helpers"

export const user = (state) => {
  return state.user
}

export const isPortalAdmin = (state) => {
  return state.user.portal_rights.includes("admin")
}

export const fullName = (state) => {
  return state.user.basic_info.first_name + " " + state.user.basic_info.last_name
}

export const avatar = (state) => {
  if (state.user.id) {
    return getOptimizedImage(
      process.env.VUE_APP_TOOLS_SERVICE_URL + `/content/users/${state.user.id}/image/?u=` + Date.now(),
      170,
      170
    )
  }

  return ""
}
